import * as React from 'react';
import { Box, Grid } from '@material-ui/core';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import { Button, Typography } from '../components/atoms';
import SEO from '../components/atoms/SEO';
import GenericPage from '../components/layout/CommonLayout/GenericPage';
import { navigate } from '../utils/dom';
import { useStyles } from '../components/pages/404';

export default function NotFoundPage() {
  const classes = useStyles();

  return (
    <GenericPage>
      <SEO title="Page not found" />
      <Grid container spacing={3}>
        <Grid item className={classes.iconCtn} xs={12} sm={6}>
          <ErrorOutlineIcon color="primary" />
          <Typography variant="h1" color="primary" fontWeight="bold">
            404
          </Typography>
        </Grid>

        <Grid item className={classes.textCtn} xs={12} sm={6}>
          <Typography variant="h1" color="primary" fontWeight="bold">
            Sorry !
          </Typography>
          <Typography variant="h6">
            {"The page you're looking for doesn't exist 😥"}
          </Typography>
          <Box className={classes.navigationCtn}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate('/')}
            >
              Go to Homepage
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>
    </GenericPage>
  );
}
