import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconCtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: theme.spacing(1),
      '& > svg': {
        fontSize: '5rem',
      },
      '& > h1': {
        fontSize: '5rem',
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        alignItems: 'flex-end',
      },
    },

    textCtn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },

    navigationCtn: {
      display: 'flex',
      gap: theme.spacing(1),
    },
  })
);
